export const TOKEN_PREFIX = window.APP_CONFIG.TOKEN_PREFIX;
export const BLOCK_EXPLORER = window.APP_CONFIG.BLOCK_EXPLORER;
export const COLORS = [
  "#1095C1",
  "#6bc44e",
  "#995fd8",
  "#a5bb36",
  "#a83ca8",
  "#3d9836",
  "#e06fce",
  "#4ac787",
  "#e14492",
  "#2e7741",
  "#576dd7",
  "#ceb13c",
  "#83579e",
  "#749232",
  "#b03a78",
  "#67a96b",
  "#dd4469",
  "#54c1ae",
  "#c93942",
  "#4db8df",
  "#e76138",
  "#5984c4",
  "#dd882e",
  "#b791dc",
  "#a6862c",
  "#de84b0",
  "#52712c",
  "#974d6f",
  "#a3b36b",
  "#b24521",
  "#30866c",
  "#e4827b",
  "#746d2b",
  "#a64d54",
  "#daa269",
  "#9c5e31",
];
export const tokenColors = {
  "DAI":"#1095C1",
  "WETH": "#6bc44e",
  "stETH": "#995fd8",
  "WBTC": "#a5bb36",
  "USDC": "a83ca8",
  "USDT": "#3d9836",
  "sUSD": "#a64d54",
  "FRAX": "#4ac787",
  "GUSD": "#e14492",
  "LUSD": "#2e7741",
  "CVX": "#576dd7",
  "FXS": "#ceb13c",
  "LQTY": "#83579e",
  "CRV": "#749232",
  "LDO": "#b03a78",
  "SNX": "#67a96b",
  "wstETH": "#dd4469",
  "3Crv": "#54c1ae",
  "steCRV":"#c93942",
  "FRAX3CRV-f":"#4db8df",
  "LUSD3CRV-f": "#e76138",
  "crvPlain3andSUSD":"#5984c4",
  "gusd3CRV":"#dd882e",
  "crvFRAX":"#b791dc",
  "cvxsteCRV":"#a6862c",
  "stkcvxsteCRV":"#de84b0",
  "cvx3Crv":"#52712c",
  "stkcvx3Crv":"#974d6f",
  "cvxFRAX3CRV-f":"#a3b36b",
  "stkcvxFRAX3CRV-f":"#b24521",
  "cvxLUSD3CRV-f":"#30866c",
  "stkcvxLUSD3CRV-f":"#e4827b",
  "cvxcrvPlain3andSUSD":"#746d2b",
  "stkcvxcrvPlain3andSUSD":"#e06fce",
  "cvxgusd3CRV":"#daa269",
  "stkcvxgusd3CRV": "#9c5e31",
  "yvDAI":"#1095C1",
  "yvUSDC":"a83ca8",
  "yvWETH":"#6bc44e",
  "yvWBTC":"#a5bb36",
  "yvCurve-stETH":"#995fd8",
  "yvCurve-FRAX":"#4ac787",
  "cvxcrvFRAX":"d97b67",
  "stkcvxcrvFRAX":"cc34de"
}